import React, { useEffect, useState } from "react";
import { BsDash } from "react-icons/bs";
import { IoMdAdd } from "react-icons/io";
import TrainingProgress from "./TrainingProgress";
import TrainingCalendar from "./Training Calendar";
import { apiCall } from "../../../_services/apiCall";
import config from "../../../config/config.json";
import { Button } from "react-bootstrap";

const TrainingDetails = ({ type }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [trainings, setTrainings] = useState([]);
  const [training, setTraining] = useState([]);

  const toggleOpen = (training) => {
    setIsOpen(!isOpen);
    setTraining(training);
  };

  const getTraineeData = async () => {
    const { isSuccess, data } = await apiCall(
      `${config.POSTLOGIN_API_URL_COMPANY}getTraineeData`,
      {},
      { type },
      "GET"
    );

    if (isSuccess) {
      setTrainings(data.data);
      setIsOpen(false);
    }
  };

  const validatedTrainingStatus = async (trainingId, trainingStatus) => {
    const { isSuccess, data } = await apiCall(
      `${config.POSTLOGIN_API_URL_COMPANY}validatedTrainingStatus`,
      {},
      { trainingId, trainingStatus },
      "POST"
    );

    if (isSuccess) {
      getTraineeData();
    }
  };

  useEffect(() => {
    getTraineeData();
  }, []);

  return (
    <div style={{ padding: "20px", marginTop: "1.5em" }}>
      {type === "REGISTERED" ? (
        <div style={{ fontSize: "28px", fontWeight: "bold" }}>My Trainings</div>
      ) : (
        <></>
      )}

      <div
        style={{
          margin: "auto",
          fontFamily: "Arial, sans-serif",
          padding: "20px",
          backgroundColor: "white",
          marginTop: "1.5em",
          borderRadius: "10px",
        }}
      >
        <p style={{ fontSize: "20px", fontWeight: "bold" }}>
          Training {type === "REGISTERED" ? "Registered" : "Completed"}
        </p>

        <div style={{ marginBottom: "20px" }}>
          <input
            type="text"
            placeholder="Search trainings..."
            style={{
              width: "100%",
              padding: "10px",
              border: "1px solid #3F88A5",
              borderRadius: "10px",
              fontSize: "16px",
            }}
          />
        </div>

        {trainings.map((training, index) => (
          <div
            key={index}
            style={{
              backgroundColor: "#E8F4FA",
              padding: "10px",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              borderRadius: "5px",
              marginBottom: "10px",
            }}
          >
            <span>
              {training.trainingTitle} - {training.trainers}
            </span>
            <button
              onClick={() => toggleOpen(training)}
              style={{
                border: "none",
                backgroundColor: "transparent",
                cursor: "pointer",
                fontSize: "24px",
                fontWeight: "bold",
                transform: isOpen ? "rotate(0deg)" : "rotate(0deg)",
                transition: "transform 0.3s ease",
              }}
            >
              {isOpen ? <BsDash /> : <IoMdAdd />}
            </button>
          </div>
        ))}

        {isOpen && (
          <div
            style={{
              border: "1px solid #ccc",
              borderRadius: "5px",
              marginTop: "10px",
              padding: "15px",
            }}
          >
            <table
              style={{
                width: "100%",
                borderCollapse: "collapse",
                textAlign: "left",
              }}
            >
              <thead>
                <tr>
                  <th>Trainer Name</th>
                  <th>Date</th>
                  <th>Time</th>
                  <th>Venue</th>
                  <th>Link For The Meeting</th>
                </tr>
              </thead>
              <tbody>
                <td>{training.trainers}</td>
                <td>{new Date(training.date).toLocaleDateString()}</td>
                <td>{`${training.fromTime} to ${training.toTime}`}</td>
                <td>
                  {training.modeOfTraining === "OFFLINE" ? "Offline" : "Online"}
                </td>
                <td>
                  <a
                    href={training.trainingLink}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {training.trainingLink}
                  </a>
                </td>
              </tbody>
            </table>

            <hr />

            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div style={{ width: "48%" }}>
                <h6>Training Description</h6>
                <p>{training.description}</p>
              </div>
              <div style={{ width: "48%" }}>
                <h6>Target Audience</h6>
                <p>{training.targetAudience}</p>
              </div>
            </div>
            {type === "REGISTERED" ? (
              <div className="w-100">
                <div
                  className="w-30 d-flex"
                  style={{ width: "20%", justifyContent: "space-between" }}
                >
                  <div className="w-45">
                    <Button
                      variant="success"
                      onClick={() =>
                        validatedTrainingStatus(training.id, "ATTENDANT")
                      }
                    >
                      Completed
                    </Button>
                  </div>

                  <div className="w-45">
                    <Button
                      variant="danger"
                      onClick={() =>
                        validatedTrainingStatus(training.id, "NON_ATTENDANT")
                      }
                    >
                      Not Completed
                    </Button>
                  </div>
                </div>
              </div>
            ) : (
              <></>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default TrainingDetails;
