import React, { useState } from 'react';
import { Form, Row, Col, Button, InputGroup } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { FiChevronDown } from "react-icons/fi";
import './SignUpTrainee.css';
import { apiCall } from '../../_services/apiCall';
import config from "../../config/config.json";
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { history } from '../../_helpers/history';
import { NavLink } from 'react-router-dom';


const RegisterForm = () => {
  const { token } = useParams();
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  // State for form fields
  const [employeeId, setEmployeeId] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [gender, setGender] = useState('MALE');
  const [categoryId, setCategoryId] = useState('');
  const [departmentId, setDepartmentId] = useState('');

  const togglePasswordVisibility = (type) => {
    if (type === 'password') {
      setShowPassword(!showPassword);
      if (showConfirmPassword) setShowConfirmPassword(false);
    } else {
      setShowConfirmPassword(!showConfirmPassword);
      if (showPassword) setShowPassword(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const payload = {
      employeeId,
      email,
      password,
      firstName,
      lastName,
      gender,
      token,
      userType: 'TRAINEE',
      categoryId: parseInt(categoryId),
      departmentId: parseInt(departmentId),
    };
    const { isSuccess, data } = await apiCall(
      `${config.AUTH_API_URL_COMPANY}signupTrainee`,
      {},
      { ...payload },
      "POST"
    );
    if (isSuccess) {
      history.push(`/#/trainee_invite/${token}`);
      window.location.reload();
    }



  };

  return (
    <div className="register-container">
      <div className="register-form">
        <h2 className="form-title">Register Now</h2>
        <Form onSubmit={handleSubmit}>
          <Form.Group as={Row} controlId="formEmployeeID">
            <Form.Label column sm="12">
              Employee ID
            </Form.Label>
            <Col sm="12">
              <Form.Control
                type="text"
                placeholder="Enter Employee Unique ID"
                value={employeeId}
                onChange={(e) => setEmployeeId(e.target.value)}
              />
            </Col>
          </Form.Group>

          <Row className='mt-4'>
            <Col sm="6">
              <Form.Group controlId="formFirstName">
                <Form.Label>First Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter first name"
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                />
              </Form.Group>
            </Col>
            <Col sm="6" className='ms-2'>
              <Form.Group controlId="formLastName">
                <Form.Label>Last Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter last name"
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row className='mt-4'>
            <Col sm="6">
              <Form.Group controlId="formEmail">
                <Form.Label>Email ID</Form.Label>
                <Form.Control
                  type="email"
                  placeholder="Enter Mail ID"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </Form.Group>
            </Col>
            <Col sm="6" className='ms-2'>
              <Form.Group controlId="formGender">
                <Form.Label>Gender</Form.Label>
                <div className="select-wrapper">
                  <Form.Control
                    as="select"
                    value={gender}
                    onChange={(e) => setGender(e.target.value)}
                  >
                    <option value="MALE">Male</option>
                    <option value="FEMALE">Female</option>
                    <option value="OTHER">Other</option>
                  </Form.Control>
                  <FiChevronDown className="dropdown-icon" />
                </div>
              </Form.Group>
            </Col>
          </Row>

          <Row className='mt-4'>
            <Col sm="6">
              <Form.Group controlId="formPassword">
                <Form.Label>Password</Form.Label>
                <InputGroup>
                  <Form.Control
                    type={showPassword ? "text" : "password"}
                    placeholder="Enter password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                  <InputGroup.Text onClick={() => togglePasswordVisibility('password')} style={{ cursor: 'pointer' }}>
                    <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
                  </InputGroup.Text>
                </InputGroup>
              </Form.Group>
            </Col>
            <Col sm="6" className='ms-2'>
              <Form.Group controlId="formConfirmPassword">
                <Form.Label>Confirm Password</Form.Label>
                <InputGroup>
                  <Form.Control
                    type={showConfirmPassword ? "text" : "password"}
                    placeholder="Confirm password"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                  />
                  <InputGroup.Text onClick={() => togglePasswordVisibility('confirm')} style={{ cursor: 'pointer' }}>
                    <FontAwesomeIcon icon={showConfirmPassword ? faEyeSlash : faEye} />
                  </InputGroup.Text>
                </InputGroup>
              </Form.Group>
            </Col>
          </Row>

          <Row className='mt-4 mb-4'>
            <Col sm="6">
              <Form.Group controlId="formCategory">
                <Form.Label>Category</Form.Label>
                <div className="select-wrapper">
                  <Form.Control
                    as="select"
                    value={categoryId}
                    onChange={(e) => setCategoryId(e.target.value)}
                  >
                    <option value="">Select category</option>
                    <option value="1">Category 1</option>
                    <option value="2">Category 2</option>
                  </Form.Control>
                  <FiChevronDown className="dropdown-icon" />
                </div>
              </Form.Group>
            </Col>
            <Col sm="6" className='ms-2'>
              <Form.Group controlId="formDepartment">
                <Form.Label>Business/Department</Form.Label>
                <div className="select-wrapper">
                  <Form.Control
                    as="select"
                    value={departmentId}
                    onChange={(e) => setDepartmentId(e.target.value)}
                  >
                    <option value="">Select Business</option>
                    <option value="1">HR</option>
                    <option value="2">EHS</option>
                  </Form.Control>
                  <FiChevronDown className="dropdown-icon" />
                </div>
              </Form.Group>
            </Col>
          </Row>

          <Button variant="primary" type="submit" className="register-btn">
            REGISTER
          </Button>
          <div className='text-center mt-2'>
            <h5>
              Already have an account?
              <NavLink to={`/trainee_invite/${token}`} style={{ color: "#3F88A5", cursor: 'pointer' }}>
                Log in
              </NavLink>
            </h5>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default RegisterForm;
