import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import deleteIcon from "../img/deleteIcon.png";
import editIcon from "../img/editIcon.png";
import { apiCall } from "../../../_services/apiCall";
import config from "../../../config/config.json";
import TrainingModal from "./TrainingModal";
import { Modal, Button } from "react-bootstrap";

const TrainingTable = () => {
  const [showModal, setShowModal] = useState(false);
  const [editTrainingId, setEditTrainingId] = useState(null);
  const [editData, setEditData] = useState(null);
  const [trainings, setTrainings] = useState([]);
  const [actionId, setActionId] = useState();
  const [actionModalShow, setActionModalShow] = useState(false);
  const handleActionClose = () => setActionModalShow(false);

  const getTrainingData = async () => {
    const { isSuccess, data } = await apiCall(
      `${config.POSTLOGIN_API_URL_COMPANY}getTrainingData`,
      {},
      {},
      "GET"
    );

    if (isSuccess) {
      const formattedData = data?.data.map((item) => ({
        item,
        id: item.id,
        date: new Date(item.date).toLocaleDateString(),
        time: `${item.fromTime} to ${item.toTime}`,
        trainer: item.trainers,
        title: item.trainingTitle,
        trainingLink: item.trainingLink,        
        mode:
          item.modeOfTraining === "ONLINE_EXTERNAL" ? "Online" : "In-person",
        targetAudience: item.targetAudience,
      }));

      setTrainings(formattedData);
    }
  };

  const handleActionAPI = async () => {
    const { isSuccess, data } = await apiCall(
      `${config.POSTLOGIN_API_URL_COMPANY}deleteTrainingData`,
      {},
      { trainingId: actionId },
      "POST"
    );

    if (isSuccess) {
      handleActionClose();
      getTrainingData();
    }
  };

  const handleDelete = (id) => {
    setActionId(id);
    setActionModalShow(true);
  };

  const handleCloseModal = () => {
    getTrainingData();
    setShowModal(false);
  };

  const handleEdit = (id, training) => {
    setShowModal(true);
    setActionId(id);
    setEditData(training.item);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setEditData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleCopyLink = (link) => {
    if (link) {
      navigator.clipboard.writeText(link)
        .then(() => {
          alert('Link copied to clipboard!');
        })
        .catch((err) => {
          console.error('Failed to copy link: ', err);
        });
    } else {
      alert('No link available to copy.');
    }
  };

  // Save changes
  const handleSave = (id) => {
    setTrainings(
      trainings.map((training) => (training.id === id ? editData : training))
    );
    setEditTrainingId(null); // Exit edit mode
  };
  useEffect(() => {
    getTrainingData();
  }, []);

  const renderTable = () => {
    return (
      <div style={tableContainerStyle}>
        <table style={tableStyle}>
          <thead>
            <tr>
              <th style={tableHeaderStyle}>#</th>
              <th style={tableHeaderStyle}>Training Date</th>
              <th style={tableHeaderStyle}>Training Time</th>
              <th style={tableHeaderStyle}>Trainer Name</th>
              <th style={tableHeaderStyle}>Training Title</th>
              <th style={tableHeaderStyle}>Mode</th>
              <th style={tableHeaderStyle}>Audience</th>
              <th style={tableHeaderStyle}>Operation</th>
            </tr>
          </thead>
          <tbody>
            {trainings.map((item) => (
              <tr
                key={item.id}
                style={{
                  backgroundColor: item.id % 2 === 0 ? "#fff" : "#f9f9f9",
                }}
              >
                <td style={tableDataStyle}>
                  {String(item.id).padStart(2, "0")}
                </td>
                {editTrainingId === item.id ? (
                  <>
                    <td style={tableDataStyle}>
                      <input
                        type="text"
                        name="date"
                        value={editData.date}
                        onChange={handleChange}
                        style={editInputStyle}
                      />
                    </td>
                    <td style={tableDataStyle}>
                      <input
                        type="text"
                        name="time"
                        value={editData.time}
                        onChange={handleChange}
                        style={editInputStyle}
                      />
                    </td>
                    <td style={tableDataStyle}>
                      <input
                        type="text"
                        name="trainer"
                        value={editData.trainer}
                        onChange={handleChange}
                        style={editInputStyle}
                      />
                    </td>
                    <td style={tableDataStyle}>
                      <input
                        type="text"
                        name="title"
                        value={editData.title}
                        onChange={handleChange}
                        style={editInputStyle}
                      />
                    </td>
                    <td style={tableDataStyle}>
                      <input
                        type="text"
                        name="mode"
                        value={editData.mode}
                        onChange={handleChange}
                        style={editInputStyle}
                      />
                    </td>
                    <td style={tableDataStyle}>
                      <input
                        type="text"
                        name="targetAudience"
                        value={editData.targetAudience}
                        onChange={handleChange}
                        style={editInputStyle}
                      />
                    </td>
                    <td style={tableDataStyle}>
                      <button
                        style={generateLinkStyle}
                        onClick={() => handleSave(item.id)}
                      >
                        Save
                      </button>
                    </td>
                  </>
                ) : (
                  <>
                    <td style={tableDataStyle}>{item.date}</td>
                    <td style={tableDataStyle}>{item.time}</td>
                    <td style={tableDataStyle}>{item.trainer}</td>
                    <td style={tableDataStyle}>{item.title}</td>
                    <td style={tableDataStyle}>{item.mode}</td>
                    <td style={tableDataStyle}>{item.targetAudience}</td>
                    <td style={tableDataStyle}>
                      <button
                        style={generateLinkStyle}
                        onClick={()=>handleCopyLink(item?.trainingLink)}
                      >
                        Generate Link
                      </button>
                      <button
                        style={generateLinkStyle}
                        onClick={()=>handleCopyLink(item?.trainingLink)}
                      >
                        Add Trainee
                      </button>
                      <span
                        onClick={() => handleEdit(item.id, item)}
                        style={{ cursor: "pointer" }}
                      >
                        <img src={editIcon} alt="edit" className="ms-2 me-2" />
                      </span>
                      <span
                        onClick={() => handleDelete(item.id)}
                        style={{ cursor: "pointer" }}
                      >
                        <img src={deleteIcon} alt="delete" />
                      </span>
                    </td>
                  </>
                )}
              </tr>
            ))}
          </tbody>
        </table>
        <Modal show={actionModalShow} onHide={handleActionClose}>
          <Modal.Header closeButton>
            <Modal.Title>Confirmation</Modal.Title>
          </Modal.Header>
          <Modal.Body>Are you sure you want to delete?</Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={handleActionAPI}>
              Yes
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  };

  return (
    <>
      <div style={headerContainerStyle}>
        <div style={headerTitleStyle}>Trainings</div>
        <div>
          <button style={createButtonStyle} onClick={() => setShowModal(true)}>
            Create new training
          </button>
        </div>
      </div>
      <div style={trainingContainerStyle}>
        <div style={searchContainerStyle}>
          <input
            type="text"
            className="form-control"
            placeholder="Search topic name"
            style={{ borderRadius: "12px" }}
          />
        </div>
        <div style={tableWrapperStyle}>{renderTable()}</div>
      </div>
      <TrainingModal
        show={showModal}
        handleClose={() => handleCloseModal()}
        actionId={actionId}
        editData={editData}
      />
    </>
  );
};

// Inline CSS styles
const headerContainerStyle = {
  display: "flex",
  justifyContent: "space-between",
  fontSize: "15px",
  marginTop: "2em",
  marginBottom: "0.5em",
};
const headerTitleStyle = {
  fontSize: "28px",
  fontWeight: "bold",
};
const createButtonStyle = {
  backgroundColor: "#3F88A5",
  color: "white",
  borderRadius: "22px",
  border: "none",
  padding: "10px 20px",
  cursor: "pointer",
};
const trainingContainerStyle = {
  border: "1px solid #DCDCDC",
  borderRadius: "10px",
  padding: "20px",
};
const tableContainerStyle = {
  marginTop: "1em",
  borderRadius: "10px",
  overflow: "hidden",
};
const tableStyle = {
  width: "100%",
  borderCollapse: "collapse",
};
const tableHeaderStyle = {
  backgroundColor: "#3F88A5",
  color: "white",
  padding: "10px",
  textAlign: "left",
};
const tableDataStyle = {
  padding: "10px",
};
const editInputStyle = {
  border: "none",
  borderBottom: "1px solid #DCDCDC",
  outline: "none",
  width: "100%",
};
const generateLinkStyle = {
  backgroundColor: "#3F88A5",
  color: "white",
  border: "none",
  padding: "5px 10px",
  borderRadius: "5px",
  cursor: "pointer",
};
const searchContainerStyle = {
  display: "flex",
  justifyContent: "flex-end",
};
const tableWrapperStyle = {
  // Add this style
  overflowX: "auto",
};

export default TrainingTable;
